import React, { useEffect, useState } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import SegmentedControl from './SegmentedControl'
import { Bar, Line } from 'react-chartjs-2'
import { t } from '../../../i18n'
import * as Analytics from '../Analytics'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import Equalizer from '@material-ui/icons/Equalizer'
import ShowChart from '@material-ui/icons/ShowChart'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import IntervalPicker from './IntervalPicker'
import { formatDate, arrayChunks, sumArrays, sheetName, graphTitle } from '../dashboardHelpers'
import ExportXLSXButton from './ExportXLSXButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

const GraphStylePicker = props => {
	const [anchorEl, setAnchorEl] = useState(null)
	const [graphStyle, setGraphStyle] = useState("bar")

	const handleClick = event => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = style => {
		if (typeof style === "string") {
			setAnchorEl(null)
			setGraphStyle(style)
			props.onStylePicked(style)
		}
	}

	return (
		<div style={{ margin: "0px 5px" }}>
			<Button aria-owns={anchorEl ? 'simple-menu' : null} aria-haspopup="true" onClick={handleClick} style={{ textTransform: 'none', border: '1px solid black', minHeight: '0px', height: '22px', minWidth: '0px', width: '30px', fontWeight: 'bold' }}>
				{graphStyle === "bar" ? <Equalizer /> : <ShowChart />}
			</Button>
			<Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} style={{ width: "90px" }} onClose={handleClose}>
				<MenuItem onClick={() => handleClose("bar")} disabled={false} selected={graphStyle === "bar"}>
					<ListItemIcon>
						<Equalizer />
					</ListItemIcon>
				</MenuItem>
				<MenuItem onClick={() => handleClose("line")} disabled={false} selected={graphStyle === "line"}>
					<ListItemIcon>
						<ShowChart />
					</ListItemIcon>
				</MenuItem>
			</Menu>
		</div>
	)
}

const UniquesCard = props => {
	const [visitData, setVisitData] = useState({})
	const [venue, setVenue] = useState(null)
	const [showCalendarPicker, setShowCalendarPicker] = useState(false)
	const [interval, setInterval] = useState(1)
	const [dateRange, setDateRange] = useState()
	const [graphStyle, setGraphStyle] = useState("bar")
	const [title, setTitle] = useState('')
	const [sheet, setSheet] = useState('')
	const [loading, setLoading] = useState(false)
	const [selectedControl, setSelectedControl] = useState(1)

	const backgroundColors = [
		'rgba(227, 255, 0, 0.7)',
		'rgb(170, 228, 121, 0.7)',
		'rgba(100, 201, 135, 0.7)',
		'rgba(35, 170, 143, 0.7)',
		'rgba(0, 137, 138, 0.7)',
		'rgba(23, 104, 119, 0.7)',
		'rgba(42, 72, 88, 0.7)',
	]

	const borderColors = [
		'rgba(227, 255, 0, 1)',
		'rgb(170, 228, 121, 1)',
		'rgba(100, 201, 135, 1)',
		'rgba(35, 170, 143, 1)',
		'rgba(0, 137, 138, 1)',
		'rgba(23, 104, 119, 1)',
		'rgba(42, 72, 88, 1)',
	]

	const getVisitData = (days, interval) => {
		setLoading(true)
		setShowCalendarPicker(false)
		setVisitData(null)
		Analytics.getUniqueVisitors(props.venue, interval).then(
			visitors => {
				setTitle(graphTitle(days))
				setSheet(sheetName(days))
				if (visitors != null) {
					let labels = interval === 1 ? visitors.labels.slice(-days) : visitors.labels.slice(0, 24)
					let data = visitors.data.slice(-days)
					if (interval != 1) {
						const chunks = arrayChunks(visitors.data, 24)
						if (days === 1) {
							data = chunks[chunks.length - 2]
						} else {
							data = sumArrays(...chunks)
						}
					}
					setVisitData({
						labels: labels,
						datasets: [{
							data: data,
							backgroundColor: backgroundColors,
							borderColor: borderColors,
							borderWidth: 1
						}]
					})
					setLoading(false)
				}
			}
		).catch(error => {
			localStorage.clear()
			props.push('/login')
		})
	}

	const controlOptions = () => {
		switch (interval) {
			case 1: return [
				{
					label: t('Analytics.week'),
					action: () => { getVisitData(7, interval); setSelectedControl(0) }
				},
				{
					label: t('Analytics.month'),
					action: () => { getVisitData(30, interval); setSelectedControl(1) }
				},
				{
					label: <div style={{ display: 'block', margin: '2px auto' }} ><svg width="16" height="16" viewBox="0 0 16 16" className="Icon_icon__2tZrT Icon_small__2paD0"><g fillRule="nonzero"><path d="M14.482 1.22h-1.067V.61a.61.61 0 00-1.22 0v.61H3.66V.61a.61.61 0 00-1.22 0v.61H1.372C.616 1.22 0 1.835 0 2.59v11.89c0 .757.616 1.373 1.372 1.373h13.11c.756 0 1.372-.616 1.372-1.372V2.592c0-.757-.616-1.372-1.372-1.372zM1.372 2.439h1.067v.61a.61.61 0 001.22 0v-.61h8.536v.61a.61.61 0 001.22 0v-.61h1.067c.084 0 .152.068.152.152v1.677H1.22V2.591c0-.084.068-.152.152-.152zm13.11 12.195H1.372a.153.153 0 01-.152-.152V5.488h13.414v8.994a.153.153 0 01-.152.152z"></path><circle cx="4.61" cy="9.61" r="1"></circle><circle cx="8.049" cy="9.61" r="1"></circle><circle cx="11.488" cy="9.61" r="1"></circle></g></svg></div>,
					action: () => { setShowCalendarPicker(!showCalendarPicker); setSelectedControl(2) }
				}
			]
			case -3600000: return [
				{
					label: t('Analytics.day'),
					action: () => { getVisitData(1, interval); setSelectedControl(0) }
				},
				{
					label: t('Analytics.week'),
					action: () => { getVisitData(7, interval); setSelectedControl(1) }
				},
				{
					label: <div style={{ display: 'block', margin: '2px auto' }} ><svg width="16" height="16" viewBox="0 0 16 16" className="Icon_icon__2tZrT Icon_small__2paD0"><g fillRule="nonzero"><path d="M14.482 1.22h-1.067V.61a.61.61 0 00-1.22 0v.61H3.66V.61a.61.61 0 00-1.22 0v.61H1.372C.616 1.22 0 1.835 0 2.59v11.89c0 .757.616 1.373 1.372 1.373h13.11c.756 0 1.372-.616 1.372-1.372V2.592c0-.757-.616-1.372-1.372-1.372zM1.372 2.439h1.067v.61a.61.61 0 001.22 0v-.61h8.536v.61a.61.61 0 001.22 0v-.61h1.067c.084 0 .152.068.152.152v1.677H1.22V2.591c0-.084.068-.152.152-.152zm13.11 12.195H1.372a.153.153 0 01-.152-.152V5.488h13.414v8.994a.153.153 0 01-.152.152z"></path><circle cx="4.61" cy="9.61" r="1"></circle><circle cx="8.049" cy="9.61" r="1"></circle><circle cx="11.488" cy="9.61" r="1"></circle></g></svg></div>,
					action: () => { setShowCalendarPicker(!showCalendarPicker); setSelectedControl(2) }
				}
			]
		}
	}

	const barOptions = {
		aspectRatio: 3.1,
		plugins: {
			legend: {
				display: false
			},
			tooltip: {
				callbacks: {
					label: context => {
						var label = context.dataset.label || ''
						if (context.parsed.y !== null) {
							label = t('Analytics.all_users') + context.parsed.y
						}
						return label
					}
				}
			}
		},
		scales: {
			y: {
				beginAtZero: true,
				ticks: {
					stepSize: 1
				}
			}
		}
	}

	const lineOptions = {
		aspectRatio: 3.1,
		plugins: {
			legend: {
				display: false
			},
			tooltip: {
				callbacks: {
					label: context => {
						var label = context.dataset.label || ''
						if (context.parsed.y !== null) {
							label = t('Analytics.all_users') + context.parsed.y
						}
						return label
					}
				}
			}
		},
		scales: {
			y: {
				beginAtZero: true,
				ticks: {
					stepSize: 1
				}
			}
		}
	}

	const exportSchema = () => {
		return [
			{
				column: t('Analytics.date'),
				type: String,
				value: visitors => visitors.date,
				width: 15
			},
			{
				column: t('Analytics.users'),
				type: Number,
				format: '#,##0',
				value: visitors => visitors.users
			}
		]
	}

	const exportData = () => {
		let data = []
		if (visitData && visitData.labels) {
			visitData.labels.forEach((l, i) => {
				data.push({ date: l, users: visitData.datasets[0].data[i] })
			})
		}
		return data
	}

	useEffect(() => {
		if (props.venue && !venue) {
			setVenue(props.venue)
			getVisitData(30, 1)
		}
	})

	const onDatesPicked = value => {
		if (value && value.length === 2) {
			setLoading(true)
			setVisitData(null)
			setDateRange(value)
			setShowCalendarPicker(false)
			setTitle(graphTitle(null, value))
			setSheet(sheetName(null, value))
			Analytics.getUniqueVisitorsInRange(props.venue, formatDate(value[0]), formatDate(value[1]), interval).then(
				visitors => {
					if (visitors != null) {
						setVisitData({
							labels: visitors.labels,
							datasets: [{
								data: visitors.data,
								backgroundColor: backgroundColors,
								borderColor: borderColors,
								borderWidth: 1
							}]
						})
						setLoading(false)
					}
				}
			).catch(error => {
				localStorage.clear()
				props.push('/login')
			})
		}
	}

	const onIntervalPicked = i => {
		if (!isNaN(i)) {
			document.querySelector('#sc10').checked = true
			setInterval(i)
			const days = i === 1 ? 7 : 1
			getVisitData(days, i)
		}
	}

	const onStylePicked = style => {
		setGraphStyle(style)
	}

	return (
		<Card style={{ width: '97%', height: '450px', margin: '5px' }}>
			<CardContent style={{ position: 'relative' }}>
				{loading &&
					<div style={{ backgroundColor: 'white', position: 'absolute', width: '100%', height: '100%', top: '50px', zIndex: 1 }}>
						<CircularProgress style={{ position: 'absolute', top: '40%', left: '40%', zIndex: 2 }} size={50} thickness={3} />
					</div>
				}
				<div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', zIndex: 1 }}>
					<Typography variant="headline" style={{ paddingLeft: '20px' }}>{t('Analytics.uniques')}</Typography>
					<div style={{ flex: 1 }}></div>
					<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
						<GraphStylePicker onStylePicked={onStylePicked} />
						<IntervalPicker onIntervalPicked={onIntervalPicked} />
						<SegmentedControl key="sc1" className="sc1" options={controlOptions()} style={{ flex: 1, width: '100%' }} selected={selectedControl} />
						<ExportXLSXButton fileName="visitors.xlsx" data={exportData()} schema={exportSchema()} sheet={sheet} />
					</div>
				</div>
				<Typography variant="body2" style={{ width: '100%', textAlign: 'center', paddingTop: '15px' }}>{title}</Typography>
				{showCalendarPicker &&
					<div style={{ display: 'block', position: 'absolute', right: '5%', zIndex: 10 }}>
						<Calendar onChange={onDatesPicked} value={dateRange} defaultView="month" selectRange={true} returnValue="range" />
					</div>
				}
				{graphStyle === "bar" && <Bar key="userBar" data={visitData} options={barOptions} />}
				{graphStyle === "line" && <Line key="userLine" data={{ ...visitData, datasets: [{ ...(visitData || { datasets: [] }).datasets[0], backgroundColor: 'rgba(0, 0, 128, 0.2)', borderColor: 'rgb(0, 0, 128)', fill: true, borderWidth: 2 }] }} options={lineOptions} />}
			</CardContent>
		</Card>
	)
}

UniquesCard.propTypes = {
	push: PropTypes.func
}

export default connect(
	null,
	{ push }
)(UniquesCard)